import React from 'react';
// import ReactDOM from 'react-dom';
import { Component } from 'react';
import jsHttpCookie from 'cookie';
import jsCookie from 'js-cookie';
import fetch from 'isomorphic-unfetch';
import { getProductsByHandle, getCollectionSearchByString } from '@/services/collections';
import { checkSizeFilterAvailble, occassionTagPrintName, capitializeString } from 'components/shared/globalFunctions';
import { isEmpty } from '@/utils/object-methods';
import Gconfig from 'globalconfig';
import Router from 'next/router';
//import { QueryClient } from 'react-query'
import { fetchSubcategoriesStarted, fetchSubcategoriesSuccess, fetchSubcategoriesfaild, fetchMainCatStarted, fetchMainCatSuccess, loadAllCompleted, clearErrot } from 'redux/actions/categoryActions';
import { fetchProductDataSuccess, fetchFilterDataSuccess, fetchPaginationDataSuccess, fetchLeftBarDataSuccess, fetchProductDataLoading } from 'redux/actions/collectionPageActions';
import { updateCustomerPreferences } from '@/redux/thunks/customerPreferencesThunks';
import { getAuthTokenfromcookie } from '@/functions/auth-token';
import { closeNavMobile } from './globalFunctions';
import { DerivedAPI } from '@/constants/global-config';

let parentType = ""
let ccc = ""
let allBrandString = ""
let allCustomCategString = ""

async function GetProductList(query, isLogin) {
  let customer_token = getAuthTokenfromcookie(false);
  let customer_ip = jsCookie.get('customer_ip');
  let request_guid = jsCookie.get('request_guid');
  let DataToReturn = '';
  if(isLogin?.customer != 'not_login' && isLogin?.customer?.email?.toLowerCase()?.indexOf('@fashionpass.com') > -1){
    query = query + '&auto_hide=false'
  }else{
    query = query + '&auto_hide=true'
  }
  if (isLogin) {
    let show_hidden_items = 3;
    if (isLogin.has_subscription) show_hidden_items = 2;
    if (isLogin !== 'not_login') {
      await getCollectionSearchByString(query, show_hidden_items, { token: customer_token, customerIP: true, guid: true }).then((data) => {
          DataToReturn = data.product_list.result_items;
        });
      return DataToReturn;
    } else {
      await getCollectionSearchByString(query, show_hidden_items, { customerIP: true, guid: true }).then((data) => {
          DataToReturn = data.product_list.result_items;
        });
      return DataToReturn;
    }
  } else {
    await getCollectionSearchByString(query, 3, { customerIP: true, guid: true }).then((data) => {
        if (data.success) DataToReturn = data.product_list.result_items;
      });
    return DataToReturn;
  }
}
async function catFilter(byname = false, catid, cathandle, pCat, level, leveltogo) {
  //debugger;
  let ParentLine = ',';
  let PPrefix = '';
  if (cathandle.indexOf(',') != -1) {
    cathandle = cathandle.split(',');
    PPrefix = cathandle[0] + '/';
    cathandle = cathandle[1];
  }
  let returned = false;
  let filterArr = pCat.sub_categories;

  let type = 'na';
  if (pCat.handle == Gconfig.collection_handle.clothing) {
    type = 'c';
  }
  if (pCat.handle == Gconfig.collection_handle.accessories) {
    type = 'a';
  }
  if (filterArr == undefined || filterArr.length < 1) {
    return null;
  }

  let cat = null;
  await filterArr.filter((Ccat, index) => {
    if (Ccat.handle == Gconfig.collection_handle.clothing) {
      type = 'c';
    }
    if (Ccat.handle == Gconfig.collection_handle.accessories) {
      type = 'a';
    }
    if (byname) {
      if (Ccat.name.includes(catid)) {
        cat = Ccat;
      }
    } else {
      if (Ccat.id == catid || Ccat.handle == cathandle) {
        cat = Ccat;
      }
    }
  });
  if (level != leveltogo && cat == null) {
    let i = 0;
    let j = filterArr.length;
    level++;
    while (i < j) {
      cat = await catFilter(byname, catid, cathandle, filterArr[i], level, leveltogo);
      if (cat != null && cat.cat != null) {
        returned = true;
        break;
      }
      i++;
    }
  }
  if (returned) {
    //let rCat = cat;
    cat.PPrefix = PPrefix;

    cat.parent.push(pCat);
    cat.ParentLine = cat.ParentLine + ParentLine + pCat.handle;
    if (cat.type == 'na') {
      cat.type = type;
    }
    return cat;
  } else {
    if (cat == null) return null;
    let p = [];
    p.push(pCat);
    return { cat: cat, parent: p, level: level, type: type, PPrefix: PPrefix, ParentLine: ParentLine + pCat.handle };
  }
}
function clone(obj) {
  var copy;

  // Handle the 3 simple types, and null or undefined
  if (null == obj || 'object' != typeof obj) return obj;

  // Handle Date
  if (obj instanceof Date) {
    copy = new Date();
    copy.setTime(obj.getTime());
    return copy;
  }

  // Handle Array
  if (obj instanceof Array) {
    copy = [];
    for (var i = 0, len = obj.length; i < len; i++) {
      copy[i] = clone(obj[i]);
    }
    return copy;
  }

  // Handle Object
  if (obj instanceof Object) {
    copy = {};
    for (var attr in obj) {
      if (obj.hasOwnProperty(attr)) copy[attr] = clone(obj[attr]);
    }
    return copy;
  }

  throw new Error("Unable to copy obj! Its type isn't supported.");
}
async function synthesizeLeftHandBar(collectionData, prevCollectionData, cHandle) {
  // console.log('collectionData, prevCollectionData', collectionData, prevCollectionData)
  let isChiledlogic = false;
  let isDynamic = false;
  let tree = collectionData.tree;
  let currentCat = null;
  //debugger;
  if (cHandle == 'all' || cHandle.indexOf('all,') != -1) {
    let clothing = await catFilter(false, null, Gconfig.collection_handle.clothing, collectionData, 0, 100);
    let accessories = await catFilter(false, null, Gconfig.collection_handle.accessories, collectionData, 0, 100);
    //console.log("accessories <<<>>>>>", accessories);
    var all = {};
    if (clothing != null && accessories != null) {
      all = clothing;
      all.cat.sub_categories = all.cat.sub_categories.concat(accessories.cat.sub_categories);
    } else if (accessories != null) {
      all = accessories;
    } else if (clothing != null) {
      all = clothing;
    }
    all = clone(all);
    all.cat.handle = 'all';
    all.cat.name = 'Filtered Results';
    currentCat = all;
    collectionData = transfertoCustom(currentCat, collectionData, cHandle);
  }
  let collection = collectionData;
  let subData = collectionData.sub_categories;
  if (collectionData.subcategory_count == 0) {
    collection = prevCollectionData;
  }

  //debugger;
  // else{
  currentCat = await catFilter(false, null, cHandle, collection, 0, 100);
  // }

  let custPart = null;
  if (currentCat == null) {
    collection = prevCollectionData;
    currentCat = await catFilter(false, null, cHandle, collection, 0, 100);
  }
  if (currentCat == null || currentCat.PPrefix == undefined) {
    console.log('category not loaded properly');
    // console.log(window.location.host + '/' + Gconfig.collection_handle.clothing);
    if(window.location.pathname != '/')
    window.location = window.location.origin + '/' + Gconfig.collection_handle.clothing;
    // Router.push('/'+Gconfig.collection_handle.clothing)
    return;
  }
  if (currentCat.PPrefix != '') {
    let x = currentCat.PPrefix.replace('/', '');
    custPart = await catFilter(false, null, x, collection, 0, 100);
  }
  let ParentData = null;
  //let
  //tree =  currentCat.cat.tree;
  subData = currentCat.cat.sub_categories;
  ////////custome page logic///////
  // if (currentCat.cat == null || currentCat.parent[0].handle.indexOf('brands') != -1 || currentCat.parent[0].handle == 'custom') {
    isChiledlogic = true;
    ParentData = collectionData;
    parentType = currentCat.parent[0].handle
    ccc = currentCat
  // }
  ////////custome page logic///////
  // else {
    if (currentCat.level > 1) {
      subData = currentCat.parent[0].sub_categories;
    }
  // }
  
  allBrandString = ""
  allCustomCategString = ""
  
  // debugger
  if(collection?.sub_categories?.length > 0) {
    let brandCollection = null
    let customCollection = null

    collection.sub_categories.map((val, ind) => {
      if(val.id == Gconfig.customCategoryId) // custom
      {
        customCollection = val
      }
      else if(val.id == Gconfig.brandCategoryId) // brands
      {
        brandCollection = val
      }

      if(brandCollection?.sub_categories?.length > 0){
        brandCollection.sub_categories.map((item) => {
          allBrandString += ","+item?.handle?.toLowerCase()
        })
      }

      if(customCollection?.sub_categories?.length > 0){
        customCollection.sub_categories.map((item) => {
          allCustomCategString += ","+item?.handle?.toLowerCase()
        })
      }

    }) 

  }

  let leftHandData = [];
  let leftHandBar = [];
  leftHandData.currentCat = currentCat;
  leftHandData.custPart = custPart;
  //if(tree!= undefined && tree.sub_item!= undefined){
  //if(tree.sub_item.name=='Clothing' || tree.sub_item.name=='Accessories' || tree.sub_item.handle=='product-categories-tree'){
  // debugger;
  let additionalSubCats = [];
  let additionalSubCatType = '';
  if (currentCat.PPrefix != '' && currentCat.PPrefix != 'all/') {
    if (currentCat.type == 'c') {
      let Xaccessories = await catFilter(false, null, Gconfig.collection_handle.accessories, collectionData, 0, 100);
      additionalSubCats = Xaccessories != null ? Xaccessories.cat.sub_categories : [];
      additionalSubCatType = 'a';
    } else if (currentCat.type == 'a') {
      let Xclothing = await catFilter(false, null, Gconfig.collection_handle.clothing, collectionData, 0, 100);
      additionalSubCats = Xclothing != null ? Xclothing.cat.sub_categories : [];
      additionalSubCatType = 'c';
    }
  }
  if (isChiledlogic && ParentData != null) {
    leftHandBar = generateBarEntries(ParentData.sub_categories, cHandle, 0, 'na', ParentData.handle == 'product-categories-tree', currentCat.PPrefix, currentCat.ParentLine, additionalSubCats, additionalSubCatType);
  } else {
    if (currentCat.PPrefix != '') {
      if (currentCat.level == 0) {
        leftHandBar = generateBarEntries(currentCat.parent[currentCat.level].sub_categories, cHandle, 0, currentCat.type, false, currentCat.PPrefix, currentCat.ParentLine, additionalSubCats, additionalSubCatType);
      } else {
        leftHandBar = generateBarEntries(currentCat.parent[currentCat.level - 1].sub_categories, cHandle, 0, currentCat.type, false, currentCat.PPrefix, currentCat.ParentLine, additionalSubCats, additionalSubCatType);
      }
    } else {
      leftHandBar = generateBarEntries(subData, cHandle, 0, currentCat.type, false, currentCat.PPrefix, additionalSubCats, additionalSubCatType);
    }
    //leftHandBar= await generateBarEntries(subData,cHandle,0,currentCat.type,false,currentCat.PPrefix)
  }

  // }
  //}
  leftHandData.bar = leftHandBar;
  return leftHandData;
}
 function generateBarEntries(array, cHandle, level, type, checkClothingorAccessories, attachHandle, ParentLine, additionalSubCats, additionalSubCatType) {
  if (checkClothingorAccessories == undefined) checkClothingorAccessories = false;
  if (array == undefined) array = [];
  let clothingCats = [];
  let accessoriesCats = [];
  if (checkClothingorAccessories) {
    attachHandle = cHandle + '/';
     array.filter(async (sub) => {
      if (sub.handle == Gconfig.collection_handle.clothing) {
        clothingCats = generateBarEntries(sub.sub_categories, cHandle, level + 1, 'c', false, attachHandle, ParentLine);
      } else {
        if (sub.handle == Gconfig.collection_handle.accessories) {
          accessoriesCats =  generateBarEntries(sub.sub_categories, cHandle, level + 1, 'a', false, attachHandle, ParentLine);
        }
      }
    });
    clothingCats = clothingCats.concat(accessoriesCats);
    return clothingCats;
  }

  let arrToReturn = [];

  array.filter(async (sub) => {
    // console.log(sub)
    if (attachHandle == 'all/') {
      type = checkSubItemHandle(sub.tree.sub_item);
      // console.log(attachHandle)
    }
    let subs = [];
    if (sub.sub_categories.length > 0 && attachHandle != 'all/') {
      subs =  generateBarEntries(sub.sub_categories, cHandle, level + 1, type, false, attachHandle);
    }

    

    let _attachHandle = attachHandle

   
    // console.log('ccc ======', allBrandString)
    // console.log('ccc ======1', allCustomCategString)

    let main_categ =  _attachHandle?.indexOf(',') > -1 ? _attachHandle.split(',')[0] : _attachHandle
    // console.log('main_categ', main_categ)
    if ((',' + cHandle + ',').indexOf(',' + sub.handle + ',') != -1 || (ParentLine + ',').indexOf(',' + sub.handle + ',') != -1) {
      // console.log('subcateg ==========> 1', level, ParentLine,  parentType, attachHandle, sub)
      
      if(_attachHandle.indexOf(',') > -1 && level == 2) {
        _attachHandle = _attachHandle.split(',')[0]+'/'
      }
      else if(level == 1 && parentType?.indexOf('brands') == -1 && parentType != 'custom') {
          if(allBrandString.indexOf(main_categ?.toLowerCase()) == -1 && allCustomCategString.indexOf(main_categ?.toLowerCase()) == -1){
            _attachHandle = "/"
          }  
          else if(_attachHandle.indexOf(',') > -1) {
            _attachHandle = _attachHandle.split(',')[0]+'/'
          } 
      }
      arrToReturn.push({ WebsiteText: sub.name, Handle: _attachHandle + sub.handle, Level: level, bold: true, subs: subs, type: type, catID: sub.id, in_stock: sub.in_stock, from:1 });
    } else {
      // console.log('subcateg ==========> 2', level, ParentLine,  parentType, attachHandle, sub)
      if(_attachHandle.indexOf(',') > -1 && level == 2) {
        _attachHandle = _attachHandle.split(',')[0]+'/'
      }
      else if(level == 1 && parentType?.indexOf('brands') == -1 && parentType != 'custom') {
          if(allBrandString.indexOf(main_categ?.toLowerCase()) == -1 && allCustomCategString.indexOf(main_categ?.toLowerCase()) == -1) {
            _attachHandle = "/"
          }
          else if(_attachHandle.indexOf(',') > -1){
            _attachHandle = _attachHandle.split(',')[0]+'/'
          }
      }
      arrToReturn.push({ WebsiteText: sub.name, Handle: _attachHandle + sub.handle, Level: level, bold: false, subs: subs, type: type, catID: sub.id, in_stock: sub.in_stock, from:2 });
    }
  });

  if (attachHandle != 'all/' && additionalSubCats != undefined && additionalSubCats.length > 0) {
    additionalSubCats.filter(async (sub) => {
      // console.log(sub)

      let subs = [];

      if ((',' + cHandle + ',').indexOf(',' + sub.handle + ',') != -1 || (ParentLine + ',').indexOf(',' + sub.handle + ',') != -1) {
        arrToReturn.push({ WebsiteText: sub.name, Handle: attachHandle + sub.handle, Level: level, bold: true, subs: subs, type: additionalSubCatType, catID: sub.id, in_stock: sub.in_stock, from:3 });
      } else {
        arrToReturn.push({ WebsiteText: sub.name, Handle: attachHandle + sub.handle, Level: level, bold: false, subs: subs, type: additionalSubCatType, catID: sub.id, in_stock: sub.in_stock, from:4 });
      }
    });
  }

  return arrToReturn;
}
function checkSubItemHandle(sub_item) {
  let type = '';
  if (sub_item.handle == 'clothing') {
    type = 'c';
    return type;
  } else if (sub_item.handle == 'accessories') {
    type = 'a';
    return type;
  } else {
    return checkSubItemHandle(sub_item.sub_item);
  }
}
function transfertoCustom(category, data, handle) {
  data.sub_categories.filter((item, index) => {
    if (item.handle == 'custom') {
      category.handle = handle;
      data.sub_categories[index].sub_categories.push(category.cat);
    }
  });
  return data;
}
let prevCollectioData = null;
function GetProductListbyhandle(query, page, onlyProduct, urlHit, updateQuery, dontConcat, sizeTogleValue, sizeTogalAvailble, isOpenBoard, noCache) {
  if (sizeTogalAvailble == undefined) sizeTogalAvailble = false;
  if (sizeTogleValue == undefined) sizeTogleValue = false;
  if (updateQuery == undefined) {
    updateQuery = true;
  }
  if (onlyProduct == undefined) {
    onlyProduct = 0;
  }
  if (dontConcat == undefined) dontConcat = false;
  let customer_token = getAuthTokenfromcookie(false);
  let DataToReturn = '';
  return async (dispatch, getState) => {
    if (urlHit) {
      let paginationData = getState().collectionPage.paginationData;
      paginationData.data.filterUrlHit = true;
      dispatch(fetchPaginationDataSuccess(paginationData.data));
    }
    let customer = getState().customer;
    let availble = '';
    let API = Gconfig.collectionAPIHandleURL;
    if (query.indexOf('All?') != -1 || query.indexOf('all?') != -1 || query.indexOf('all,') != -1) {
      API = Gconfig.collectionAPIURLDynamic;
    }
    if(urlHit && location.pathname.indexOf('/all') > -1){
      API = Gconfig.collectionAPIURLDynamic;
      // query = query.split('?')[1];
    }
    // if(urlHit && query.indexOf("filter_tags_any_list=&") == -1){
    //   query = query.split('?')[0]+"?filter_tags_any_list=,&filter_vendor_ids=&filter_sizes=&"
    // }
    let lastResultNotFound = getState().collectionPage.paginationData.data.lastResultNotFound;
    let sortData = getState()?.collectionPage?.filterData?.data.sort
    let sortFilter = ''
    sortData?.filter(item => {
      if(item.selected && item.name == 'Highest Rated') {
        sortFilter = 'rev'
      }
      if((!item.selected && item.name == 'Highest Rated') || (item.selected && item.name == 'Featured')) {
        sortFilter = ''
      }
    })
    if(query.toLowerCase().indexOf('highest-rated')>-1 && sortFilter == '') {
      sortFilter = 'rev:4.5'
    }
    let RunQuery = '';
    let show_hidden_items = 3;
    let excludesTags = (query.indexOf('maternity')>-1 || query.indexOf('bumpsuit')>-1) ?"":"&exclude_tags=bump-photo"
    if (customer.customer != undefined && customer.customer.subscription_name) show_hidden_items = 2;
    if (location.pathname.indexOf('my-favorites') > -1 ) {
      if (isOpenBoard) RunQuery = API + query.toLowerCase() + `items_per_page=48&excludes=stats,categorylist&sort_by=${sortFilter != '' ? sortFilter : `fav_board_id:${isOpenBoard}`}&sort_order=desc&page=${page}&show_hidden_items=${show_hidden_items}${excludesTags}`;
      else RunQuery = API + query.toLowerCase() + `items_per_page=48&excludes=stats,categorylist&sort_by=${sortFilter != '' ? sortFilter : `fav_date`}&sort_order=desc&page=${page}&show_hidden_items=${show_hidden_items}${excludesTags}`;
    } else {
      if(''+query.indexOf('what-you-missed') > -1) {
        RunQuery = API + query.toLowerCase() + `items_per_page=48&excludes=stats,categorylist&sort_by=${sortFilter != '' ? sortFilter :"days"}&sort_order=asc&page=${page}&show_hidden_items=${show_hidden_items}${excludesTags}`;
      }
      else {
        RunQuery = API + query.toLowerCase() + `items_per_page=48&excludes=stats,categorylist&sort_by=${sortFilter != '' ? sortFilter :"pos"}&sort_order=desc&page=${page}&show_hidden_items=${show_hidden_items}${excludesTags}`;
      }
    }
    if (location.pathname.indexOf('sale') > -1) {
      RunQuery = RunQuery+'&category=custom'
    }
    if (query.indexOf('All?') != -1 || query.indexOf('all?') != -1 || query.indexOf('all,') != -1) {
      //RunQuery= RunQuery.replace('All','')
      // debugger;
      let allPart = RunQuery.split('?')[0];
      allPart = allPart.replace(API, '');

      RunQuery = RunQuery.replace(allPart + '?', '?category_handles=' + allPart + '&');
      //RunQuery= RunQuery.replace('all','')
    }
    let QueryParts = query.toLowerCase().split('?');
    if (QueryParts[0].indexOf(',') != -1 && QueryParts[0].indexOf('all,') == -1) {
      RunQuery = Gconfig.collectionAPIURL + `category_handles=${QueryParts[0]}&${QueryParts[1]}items_per_page=48&page=${page}&category_search_type=1&show_hidden_items=${show_hidden_items}${excludesTags}`;
    }
    let isSub = customer.customer != undefined && customer.customer.subscription_name != '' && customer.customer.subscription_name != undefined;
    let params = new URLSearchParams(RunQuery);
    if (''+sizeTogalAvailble == 'true' && ''+sizeTogleValue == 'true') {
      availble += '&in_stock=1';
      availble += '&in_stock_sizes=1';
    }else{
      availble += '&in_stock=0';
      if(params.get('filter_sizes') != ''){
        availble += '&in_stock_sizes=1';
      }else{
        availble += '&in_stock_sizes=0';
      }
    }
    // if (isSub) {
    //   availble += '&in_stock_sizes=0';
    // }
    if (lastResultNotFound) {
      availble = '';
    }
    RunQuery += availble;
    if (location.pathname.indexOf('my-favorites') > -1  ) {
      if (isOpenBoard) RunQuery += '&other_filter=only_board_id:' + isOpenBoard;
      else RunQuery += '&other_filter=only_customer_favs';
    }
    if (location.pathname.indexOf('summer-flash-sale') > -1) {
      RunQuery += '&category=custom';
    }
    let request_guid = jsCookie.get('request_guid');
    // if(location.pathname.indexOf('my-favorites') == -1 ) {
      if(isSub)
        RunQuery += '&isprice_for_customer=true';
      else
        RunQuery += '&isprice_for_customer=false';
    // }
    RunQuery += '&new_inStockFlag=true'
    let isAutoHide = 'true'
    if(customer.customer != undefined && customer.customer?.email?.toLowerCase()?.indexOf('@fashionpass.com') > -1) isAutoHide= 'false'
    RunQuery += '&auto_hide='+isAutoHide
    // console.log(RunQuery, "noCache")
    await getProductsByHandle(RunQuery.replace('ddfdfdfdf,', ''), noCache, { token: customer_token, guid: true }).then(async (data) => {
        //await fetch(RunQuery, {
        //   method: 'GET', headers: { 'Authorization': 'Bearer ' + customer_token }
        //}).then(response => response.json()).then(async (data) => {
        DataToReturn = data;
        //debugger;
        jsCookie.set('fetchFailed', false);
        let privious = getState().collectionPage;
        prevCollectioData = privious.leftBarData != undefined && privious.leftBarData.data.oldResponse;
        // console.log(data);
        if (data.product_list && data.product_list.result_items.length < 1) {
          // data= privious;
          //return null;
        }
        if (data.success) {
          if(data.product_list.result_items.length == 0 && urlHit && location.pathname.indexOf('my-favorites') == -1){
            Router.push('/');
          }
          let cHandle = query.split('?')[0];
          let virtualColl = null;
          let virtualTags = [];
          if (data.product_list.virtual_link) {
            virtualColl = data.virtual_link_data.original_category;
            data.virtual_link_data.filter_tags_all != undefined &&
              data.virtual_link_data.filter_tags_all.split(',').filter((item, index) => {
                virtualTags.push(item);
              });
            data.virtual_link_data.filter_tags_any != undefined &&
              data.virtual_link_data.filter_tags_any.split(',').filter((item, index) => {
                virtualTags.push(item);
              });
            // cHandle = virtualColl.handle;
            //data = transfertoCustom(virtualColl,data,cHandle);
          }

          if (data.product_list.category_detail.subcategory_count != 0) {
            // prevCollectioData = data;
          }
          //console.log("444444iiiuiuiuiu"+onlyProduct);
          if (onlyProduct == 1) {
            // console.log('OP---------++++++++---++++++---1');
            //DataToReturn= privious.leftHandData.oldResponse;
            let paginationData = privious.paginationData;
            paginationData.data.filterHit = false;
            paginationData.data.noFoundClear = false;
            paginationData.data.current_page = data.product_list.current_page;
            paginationData.data.total_pages = data.product_list.pages;
            paginationData.data.total_Items = data.product_list.page_items_total;
            paginationData.data.filterUrlHit = false
            if (updateQuery && location.pathname.indexOf('my-favorites') > -1) {
              let allPart = query.indexOf('?') > -1 ? query.split('?')[0] : query.split('&')[0];
              // allPart = allPart.replace(API, '');
              let sym = query.indexOf('?') > -1 ? '?' : '&'
              paginationData.data.query = allPart + sym
            }
            let noFoundMessage = generateSoldOutMessage(privious.filterData.data, privious.leftBarData.data.currentCat.cat.name);
            let dynamicName = noFoundMessage.dynamicTitle;
            noFoundMessage = noFoundMessage.fm;
            // noFoundMessage = 'No Size small, red, Free People, cropped jeans & denim found.'
            let messageFilter = 'No ';
            let _delimeter = ' ';
            if (noFoundMessage.length > 0) {
              let _temp = noFoundMessage.filter((el) => el != null);
              if (_temp.length > 1 || _temp[0].split(',').length > 1) _delimeter = ', ';
            }
            noFoundMessage.forEach(function (value, index) {
              if (value != '' && index == 0) {
                messageFilter += ' ' + value + _delimeter;
              } else if (value != '') {
                messageFilter += value + _delimeter;
              }
            });
            var parent_text = '';
            if (privious.leftBarData.data.currentCat.level == 2) parent_text = privious.leftBarData.data.currentCat.cat.tree.sub_item.name;
            messageFilter += capitializeString(privious?.leftBarData?.data?.currentCat?.cat?.name);
            if (parent_text != '') messageFilter += ', ' + capitializeString(parent_text);
            messageFilter += ' found.';
            paginationData.data.soldOutMessage = messageFilter;
            //console.log(messageFilter);
            paginationData.data.clearCall = false;
            dispatch(fetchPaginationDataSuccess(paginationData.data));
            dispatch(fetchProductDataSuccess(data.product_list.result_items));
          } else {
            let sizes = [];
            if (data.product_list.size_list != null)
              sizes = data.product_list.size_list.map((size) => {
                return { size: size, selected: false };
              });
            let colors = [];
            if (data.product_list.color_list != null)
              colors = data.product_list.color_list.map((color) => {
                if (virtualTags.indexOf(color.toLowerCase()) != -1) {
                  return { color: color, selected: true, paterns: false };
                } else {
                  return { color: color, selected: false, paterns: false };
                }
              });
            if (data.product_list.tag_list) {
              data.product_list.tag_list.filter((tag) => {
                if (Gconfig.paterns_prints.indexOf(tag.tag_name) > -1) {
                  colors.push({ color: tag.tag_name, selected: false, paterns: true });
                }
              });
            }
            let brandCovertArry = [];
            let allBrands = getState().brand.brand
            let availableBrands = []
            if(allBrands?.length > 0){
              allBrands.map((brand)=>{
                if(!brand.collection_hidden && brand.collection_status){
                  availableBrands.push(brand.name)
                }
              })
            }
            if (data.product_list.vendor_list != null) brandCovertArry = Object.entries(data.product_list.vendor_list);
            let brands = brandCovertArry.map((brand) => {
              if(availableBrands?.length > 0){
                if(availableBrands.indexOf(brand[1]) > -1){
                  return { brand: brand[1], brand_id: brand[0], selected: false};
                }else{
                  return ''
                }
              }else{
                return { brand: brand[1], brand_id: brand[0], selected: false};
              }
            });
            let occasion = [];
            if (data.product_list.tag_list) {
              occasion = data.product_list.tag_list.filter((tag) => {
                if (Gconfig.webSiteTagList.indexOf(tag.website_text) > -1) {
                  tag.selected = false;
                  if (virtualTags.indexOf(tag.website_text.toLowerCase()) != -1) tag.selected = true;
                  return tag;
                }
              });
            }
            let otherFilter = []
            if (location.pathname.indexOf('my-favorites') > -1  ) {
              if (data.product_list.tag_list) {
                let nectLineFilter = []
                let sleeveFilter = []
                let lengthFilter = []
                data.product_list.tag_list.map((tag) => {
                  if (Gconfig.neckLineList.indexOf(tag.website_text) > -1) {
                    let obj = {
                        "tag_id": tag.tag_id,
                        "tag_name": tag.tag_name,
                        "website_text": tag.website_text,
                        "selected": false,
                        "type": "neckLineFilter"
                    }
                    if (virtualTags.indexOf(tag.website_text.toLowerCase()) != -1) {
                        obj.selected = true;
                      }
                      nectLineFilter.push(obj)
                  }
                });

                data.product_list.tag_list.map((tag) => {
                  if (Gconfig.sleeveList.indexOf(tag.website_text) > -1) {
                    let obj = {
                        "tag_id": tag.tag_id,
                        "tag_name": tag.tag_name,
                        "website_text": tag.website_text,
                        "selected": false,
                        "type": "sleeveFilter"
                    }
                    if (virtualTags.indexOf(tag.website_text.toLowerCase()) != -1) {
                        obj.selected = true;
                      }
                      sleeveFilter.push(obj)
                  }
                });

                data.product_list.tag_list.map((tag) => {
                  if (Gconfig.lengthList.indexOf(tag.website_text) > -1) {
                    let obj = {
                        "tag_id": tag.tag_id,
                        "tag_name": tag.tag_name,
                        "website_text": tag.website_text,
                        "selected": false,
                        "type": "lengthFilter"
                    }
                    if (virtualTags.indexOf(tag.website_text.toLowerCase()) != -1) {
                        obj.selected = true;
                      }
                      lengthFilter.push(obj)
                  }
                });
                otherFilter = otherFilter.concat(nectLineFilter).concat(sleeveFilter).concat(lengthFilter)
              }
            }
            if(occasion.length > 0){
              occasion = occasion.map((val,ind)=>{
                  val.website_text = occassionTagPrintName(val.website_text)
                  return val
              })
              occasion = occasion.sort(function (a, b) {
                if (a.website_text < b.website_text) {
                  return -1;
                }
                if (a.website_text > b.website_text) {
                  return 1;
                }
                return 0;
              });
            }
            let filter = [];
            if (data.product_list.dropdown_filtersets && data.product_list.dropdown_filtersets.length > 0) {
              data.product_list.dropdown_filtersets.map((filters) => {
                filters.subfilter_categories.map((sunFilter) => {
                  sunFilter.subfilter_lists.map((subList) => {
                    subList.selected = false;
                    if (virtualTags.indexOf(subList.website_text.toLowerCase()) != -1) subList.selected = true;
                  });
                });
              });
              filter = data.product_list.dropdown_filtersets;
            }
            //debugger;
            if (dontConcat) {
              privious.filterData.data = {};
            }
            // console.log('privious.filterData.data ====>>>', privious.filterData);
            // if(onlyProduct == 2){
              if (privious.filterData.data.hasOwnProperty('colors') || privious.filterData.data.hasOwnProperty('sizes')) {
              query = query.split('?')[0] + '?';
              let oldColor =
                (await privious.filterData.data.colors) != undefined
                  ? privious.filterData.data.colors.filter((x) => {
                      // debugger;
                      if (x.selected) return true;
                    })
                  : {};
              //debugger;
              let oldSizes =
                (await privious.filterData.data.sizes) != undefined
                  ? privious.filterData.data.sizes.filter((x) => {
                      if (x.selected) return true;
                    })
                  : {};
              let oldOccasions =
                (await privious.filterData.data.occasion) != undefined
                  ? privious.filterData.data.occasion.filter((x) => {
                      if (x.selected) return true;
                    })
                  : {};
              // if(location?.pathname.indexOf('/my-favorites') > -1){
                let oldOtherFilter = 
                (await privious.filterData.data.otherFilter) != undefined
                ? privious.filterData.data.otherFilter.filter((x) => {
                    if (x.selected) return true;
                  })
                : {};
              // }
              let oldBrands =
                (await privious.filterData.data.brands) != undefined
                  ? privious.filterData.data.brands.filter((x) => {
                      // return true;
                      if (x.selected) return true;
                    })
                  : {};
              let oldfilterLength = [];
              (await privious.filterData.data.filter) != undefined
                ? privious.filterData.data.filter.filter((x) => {
                    x.subfilter_categories.map((sunFilter) => {
                      let obj = { filterId: sunFilter.id, subFilter: [], filterName: sunFilter.name };
                      sunFilter.subfilter_lists.map((subList) => {
                        if (subList.selected) obj.subFilter.push(subList);
                      });
                      oldfilterLength.push(obj);
                    });
                    return oldfilterLength;
                  })
                : {};
              // debugger;
              oldSizes.length > 0 &&
                (await sizes.map(async (item, index) => {
                  await oldSizes.filter(async (item2, index2) => {
                    if (item2.size == item.size) {
                      item.selected = true;

                      await oldSizes.splice(index2, 1);
                    }
                    if (item2.size == '') {
                      await oldSizes.splice(index2, 1);
                    }
                  });
                }));
              // debugger;

              sizes = sizes.concat(oldSizes);

              oldBrands.length > 0 &&
                brands.map((item, index) => {
                  oldBrands.filter((item2, index2) => {
                    if (item2.brand == item.brand) {
                      item.selected = true;
                      oldBrands.splice(index2, 1);
                    }
                  });
                });
              brands = brands.concat(oldBrands);
              oldOccasions.length > 0 &&
                occasion.map((item, index) => {
                  oldOccasions.filter((item2, index2) => {
                    if (item2.tag_name == item.tag_name) {
                      item.selected = true;
                      oldOccasions.splice(index2, 1);
                    }
                  });
                });
              occasion = occasion.concat(oldOccasions);
              // if(location?.pathname.indexOf('/my-favorites') > -1){
                oldOtherFilter.length > 0 &&
                  otherFilter.map((item, index) => {
                    oldOtherFilter.filter((item2, index2) => {
                      if (item2.tag_name == item.tag_name) {
                        item.selected = true;
                        oldOtherFilter.splice(index2, 1);
                      }
                    });
                  });
                otherFilter = otherFilter.concat(oldOtherFilter);
              // }
              oldColor.length > 0 &&
                colors.map((item, index) => {
                  oldColor.filter((item2, index2) => {
                    if (item2.color == item.color) {
                      item.selected = true;
                      item.paterns = item2.paterns
                      oldColor.splice(index2, 1);
                    }
                  });
                });
              // colors = colors.concat(oldColor);
              // console.log(oldfilterLength)
              filter.map((filterNew) => {
                filterNew.subfilter_categories.map((subFilter) => {
                  oldfilterLength.filter((oldfilter) => {
                    if (oldfilter.filterId == subFilter.id && oldfilter.subFilter.length > 0) {
                      // subFilter.subfilter_lists.map((list, index) => {
                      //   oldfilter.subFilter.map((tag) => {
                      //     if (list.id == tag.id) {
                      //       subFilter.subfilter_lists.splice(index, 1);
                      //     }
                      //   });
                      // });

                      subFilter.subfilter_lists.map((item, index) => {
                        oldfilter.subFilter.filter((item2, index2) => {
                          if (item2.website_text.toLowerCase() == item.website_text.toLowerCase()) {
                            subFilter.subfilter_lists[index].selected = true;
                            oldfilter.subFilter.splice(index2, 1);
                          }
                        });
                      });
                    }
                  });
                });
              });
            }
            let noPatternColor = colors.filter(color => {if (color.paterns == false) return true; });
            let patternColor = colors.filter(color => {if (color.paterns == true) return true; });

            let colorList1=[]
            let colorList2=[]

            let patterList1=[]
            let patterList2=[]

            let colorList = ['red','orange','yellow','green','blue','purple','pink']
            let patternList = ['gold','silver','metallic']
            
            colorList.map((val,ind)=>{
              noPatternColor.map((color,ind)=>{
                if(color.color == val){
                  colorList1.push(color)
                }
              })
            })
            noPatternColor.map((val,ind)=>{
              if( colorList.indexOf(val.color) == -1){
                colorList2.push(val)
              }
            })
            patternList.map((val,ind)=>{
              patternColor.map((color,ind)=>{
                if(color.color == val){
                  patterList1.push(color)
                }
              })
            })
            patternColor.map((val,ind)=>{
              if( patternList.indexOf(val.color) == -1){
                patterList2.push(val)
              }
            })
            colors = colorList1.concat(colorList2).concat(patterList1).concat(patterList2)
       
            // Price-filter-work ---------->
            let price = []
            if(privious.filterData.data.hasOwnProperty('price')) {
              price = privious.filterData.data.price
            }

            // Price-filter-work ---------->
            let pricelist = []
            // if(data.product_list && data.product_list.price_List) {
            //   pricelist = data.product_list.price_List
            //   //convert all decimal values into integer values 
            //   if(pricelist && pricelist.length > 0){
            //     pricelist = pricelist.map((value) => {
            //       return Math.floor(value);
            //     });
            //   }
            //   // is arr contain  only one value add 0 to make slider range
            //   if(pricelist.length == 1 && price.length > 0) {
            //     pricelist = [0, pricelist[0]];
            //   }
            // }
            if(data.product_list && data.product_list.price_List && data.product_list.price_List.length > 1) {
              pricelist = data.product_list.price_List || []
              pricelist = pricelist.sort((a, b)=>{return a - b});
              // is arr contain  only one value add 0 to make slider range
              if(pricelist.length == 1 && price.length > 0) {
                pricelist = [0, pricelist[0]];
              }
              // convert decimal to floor and ciel
              pricelist[0] = Math.floor(pricelist[0])
              pricelist[pricelist.length - 1] = Math.ceil(pricelist[pricelist.length - 1])
            }
            let sortList = [{ 'name': 'Featured', 'selected': false, 'index': 0 }, { 'name': 'Highest Rated', 'selected': false, 'index': 1 }]
            let seasonList = [{ 'name': 'fall-winter', 'selected': false, 'index': 0 }, { 'name': 'spring-summer', 'selected': false, 'index': 1 }]
            if(privious.filterData?.data?.hasOwnProperty('sort')) {
              sortList = privious.filterData?.data?.sort
            }
            if(privious.filterData?.data?.hasOwnProperty('season')) {
              seasonList = privious.filterData?.data?.season
            }

            // }
            /////Genereic filters added
            let filterData = { sizes: sizes, filter: filter, colors: colors, brands: brands, occasion: occasion, filters: privious.filterData.data.filters, pricelist: pricelist, price: price, sort: sortList, season: seasonList, otherFilter: otherFilter };
            // console.log("filterData BY HANDLE ==>> ", filterData );

            // let pageCount = data.product_list.search_items_total != 0 ? Math.ceil(data.product_list.search_items_total / data.product_list.page_items_total) : 0;
            let pageCount = 1
            if(data.product_list && data.product_list.pages){
              pageCount = data.product_list.pages;
            }else{
              pageCount = data.product_list.search_items_total != 0 ? Math.ceil(data.product_list.search_items_total / data.product_list.page_items_total) : 0;
            }
            if (location.pathname.indexOf('my-favorites') > -1) {
              pageCount = data.product_list.pages;
            }
            let paginationData = { current_page: data.product_list.current_page, total_Items: data.product_list.page_items_total, total_pages: pageCount, query: query, filterHit: false, filterUrlHit: false };
            // debugger;
            if (!updateQuery && privious.paginationData.data.query != undefined) {
              paginationData.query = privious.paginationData.data.query;
            }

            //debugger;
            if (prevCollectioData == null || prevCollectioData.product_list == undefined) prevCollectioData = data;
            let leftHandData = null;
            if (location.pathname.indexOf('my-favorites') == -1) {
              leftHandData = await synthesizeLeftHandBar(data.product_list.category_detail, prevCollectioData.product_list.category_detail, cHandle);
            } else {
              leftHandData = { currentCat: { cat: { name: '' } } };
            }

            let noFoundMessage = generateSoldOutMessage(filterData, leftHandData.currentCat.cat.name);
            let dynamicName = noFoundMessage.dynamicTitle;
            let finalMessageNoSizeText = noFoundMessage.finalMessageNoSizeText;
            noFoundMessage = noFoundMessage.fm;
            let messageFilter = 'No ';
            let _delimeter = ' ';
            if (noFoundMessage.length > 0) {
              let _temp = noFoundMessage.filter((el) => el != null);
              if (_temp.length > 1 || _temp[0].split(',').length > 1) _delimeter = ', ';
            }
            noFoundMessage.forEach(function (value, index) {
              if (value != '' && index == 0) {
                messageFilter += 'size ' + value + _delimeter;
              } else if (value != '') {
                messageFilter += value + _delimeter;
              }
            });
            var parent_text = '';
            if (leftHandData.currentCat.level == 2) parent_text = leftHandData.currentCat.cat.tree.sub_item.name;
            messageFilter += leftHandData.currentCat.cat.name.toLowerCase();
            if (parent_text != '') messageFilter += ', ' + parent_text.toLowerCase();
            messageFilter += ' found.';
            paginationData.soldOutMessage = messageFilter;
            if (privious.productData.data.length == 0) paginationData.clearCall = privious.paginationData.data.clearCall;
            leftHandData.virtual_collection = virtualColl;
            leftHandData.dynamicName = dynamicName;
            leftHandData.finalMessageNoSizeText = finalMessageNoSizeText;
            leftHandData.loadTime = Date.now();
            // console.log('No found msg=========>>>>', dynamicName);
            //console.log("leftHandData =========>>>>",leftHandData );
            // dispatch(fetchProductDataSuccess(makeBlankItem()));
            if (paginationData.data != undefined) paginationData.data.clearCall = false;
            leftHandData.oldResponse = DataToReturn;
            if (lastResultNotFound) {
              paginationData.showSoldOut = true;
            }
            dispatch(fetchPaginationDataSuccess(paginationData));
            dispatch(fetchFilterDataSuccess(filterData));
            dispatch(fetchLeftBarDataSuccess(leftHandData));
            dispatch(fetchProductDataSuccess(data.product_list.result_items));
            if (Gconfig.environment == 'production' && location.host == 'www.fashionpass.com') {
              if (gtag) {
                let gTagItems = [];
                data.product_list.result_items &&
                  data.product_list.result_items.length > 0 &&
                  data.product_list.result_items.map((val, ind) => {
                    let data = {
                      item_id: val.id,
                      item_name: val.title,
                      currency: 'USD',
                      index: ind,
                      item_brand: val.vendor,
                      item_variant: val.colors && val.colors.length > 0 && val.colors[0],
                      price: val.price,
                      quantity: 1,
                    };
                    gTagItems.push(data);
                  });
                gtag({ ecommerce: null });
                gtag({
                  event: 'view_item',
                  ecommerce: {
                    items: gTagItems,
                  },
                });
              }
            }
          }
        } else {
          console.log('bad request');
          Router.push('/');
        }
      })
      .catch(function () {
        console.log('error');
        if (!jsCookie.get('fetchFailed')) {
          jsCookie.set('fetchFailed', true);
          GetProductListbyhandle(query, page, onlyProduct, urlHit, updateQuery, dontConcat, sizeTogleValue, sizeTogalAvailble);
        }
      });
    return DataToReturn;
  };
}
function makeBlankItem() {
  var products48 = [];
  var xproduct = {
    id: '000',
    title: '',
    handle: '',
    prduct_cost: '00',
    vendor: '',
    vendor_handle: '',
    pos: '00',
    created: '0000-00-00T00:00:00',
    shopify_id: '0',
    thumbnail_image: 'https://cdn.shopify.com/s/files/1/1296/1129/products/flutter-sleeve-dress-1-state-fashionpass-front.jpg?v=1593538221',
    attributes: {},
    style_image: '',
    images: ['shimer.gif', 'shimer.gif'],
    colors: ['black'],
    sizes: {},
    general_sizes: ['XS', 'S', 'M', 'L', 'XL'],
    is_favorite: false,
    favorite_boards: null,
    tags: [2, 57, 84, 73, 306, 20, 25, 76, 319, 307, 308, 309, 271],
    paired_colors: [],
  };
  for (var i = 0; i < 49; i++) {
    products48.push(xproduct);
  }
  return products48;
}

// #region ITEM REMOVED - getMainCategory - Created new one at @/redux/thunks/categoryThunks location
function getMainCategory(Cid, ns, url, server) {
  if (ns == undefined) ns = 'Def';
  if (Cid == undefined || Cid == 'main') {
    Cid = Gconfig.Parent;
  }
  return async (dispatch, getState) => {
    let customer_ip = jsCookie.get('customer_ip');
    let request_guid = jsCookie.get('request_guid');
    var oldValues = getState()['cats' + ns];
    if (oldValues.currentCat != null && oldValues.currentCat.sub_categories.length < 1) dispatch(fetchMainCatStarted(ns));
    let depth = 1;
    if (ns.indexOf('menu') != -1) {
      depth = 2;
    }
    console.log(Gconfig.fpAPI + `Collections/Category/${Cid}/${depth}`);
    await fetch(Gconfig.fpAPI + `Collections/Category/${Cid}/${depth}`, {
      method: 'GET',
      headers: { Authorization: 'Bearer ' + getAuthTokenfromcookie(server), 'Content-Type': 'application/json', 'X-Request-For': customer_ip, guid: request_guid },
    })
      .then(async (response) => await response.json())
      .then((data) => {
        if (data.errors == undefined && data.success) {
          //console.log("URL is   "+url)
          try {
            // debugger;
            if(url && url[0] == '/' && url[1] == '?'){
              url = '/'
            }
            if (Window != undefined) {
              url = window.location.pathname;
            }
          } catch (error) {
            // this.setState({ addToCartClicked: false });
          }
          if (url != undefined && url == '/') {
            let x = data.data.sub_categories;
            x = x.filter((item, index) => {
              if (item.name.includes('internal')) {
                return false;
              }
              return true;
            });
            data.data.sub_categories = x;
          } else {
            let y = data.data.sub_categories;
            y = y.filter((item, index) => {
              if (item.name.includes('main')) {
                return false;
              }
              return true;
            });
            data.data.sub_categories = y;
          }
          dispatch(fetchSubcategoriesSuccess(ns, data.data.sub_categories));
          dispatch(fetchMainCatSuccess(ns, data.data));
        } else {
          dispatch(fetchSubcategoriesfaild(ns, data));
        }
      });
  };
}
// #endregion

function generateSoldOutMessage(filterData, currentcollection) {
  if (filterData == undefined) return;
  // debugger;
  filterData.leftHandBar != undefined && filterData.leftHandBar.currentCat != undefined && (currentcollection = filterData.leftBarData.currentCat.cat.name);
  //filterData =  filterData.filterData.data;

  let Qpart = currentcollection;
  let finalMessage = [];
  let finalMessageNoSize = [];
  let sizes = [];
  filterData.sizes != undefined &&
    filterData.sizes.filter((size) => {
      return size.selected == true && sizes.push(capitializeString(size?.size));
    });
  let colors = [];
  filterData.colors != undefined &&
    filterData.colors.filter((color) => {
      return color.selected == true && colors.push(capitializeString(color?.color));
    });

  let brands = [];
  filterData.brands != undefined &&
    filterData.brands.filter((brand) => {
      return brand.selected == true && brands.push(capitializeString(brand?.brand));
    });
  let occasion = [];
  filterData.occasion != undefined &&
    filterData.occasion.filter((acc) => {
      return acc.selected == true && occasion.push(capitializeString(acc?.website_text));
    });
  let filterst = [];
  if (filterData.filter != undefined && filterData.filter.length > 0) {
    filterData.filter.map((filter) => {
      filter.subfilter_categories.map((sunFilter) => {
        sunFilter.subfilter_lists.map((subList) => {
          if (subList.selected == true) {
            filterst.push(capitializeString(subList?.website_text));
          }
        });
      });
    });
  }
  if (sizes.length > 0) finalMessage.push(sizes.join(', '));
  if (colors.length > 0) finalMessage.push(colors.join(', '));
  if (brands.length > 0) finalMessage.push(brands.join(', '));
  if (occasion.length > 0) finalMessage.push(occasion.join(', '));
  if (filterst.length > 0) finalMessage.push(filterst.join(', '));
  if (filterData.filters != '' && filterData.filters != undefined) finalMessage.push(filterData.filters);
  let finalMessageText = finalMessage.join(', ');
  // if (sizes.length > 0) finalMessage.push((sizes.join(', ')));
  if (colors.length > 0) finalMessageNoSize.push(colors.join(', '));
  if (brands.length > 0) finalMessageNoSize.push(brands.join(', '));
  if (occasion.length > 0) finalMessageNoSize.push(occasion.join(', '));
  if (filterst.length > 0) finalMessageNoSize.push(filterst.join(', '));
  if (filterData.filters != '' && filterData.filters != undefined) finalMessageNoSize.push(filterData.filters);
  let finalMessageNoSizeText = finalMessageNoSize.join(', ');
  // finalMessageText = finalMessageText.replace(',','')
  //console.log('+++', finalMessageText, finalMessage);
  return { fm: finalMessage, dynamicTitle: finalMessageText, finalMessageNoSizeText: finalMessageNoSizeText };
}
// ITEM REMOVED - filterQuerySubmit - Created new one at @/redux/thunks/collectionPageThunks location

function onlyUnique(value, index, self) {
  if (value.handle == Gconfig.contentSettings.productCategoryParent || value.handle == 'brands' || value.handle == 'custom' || value.handle == Gconfig.collection_handle.clothing) {
    return false;
  }
  return self.indexOf(value) === index;
}

function productHeading(collectionData) {
  let cc = collectionData.leftBarData.data.currentCat;
  let custPart = collectionData.leftBarData.data.custPart;
  if (custPart != null) {
    cc.parent.push(custPart.cat);
    cc.parent = cc.parent.filter((x) => {
      if (x.handle == Gconfig.collection_handle.clothing || x.handle == Gconfig.collection_handle.accessories) {
        return false;
      }
      return true;
    });
  }
  let cats = cc?.parent;
  cats = cats?.filter(onlyUnique);
  return { currentCat: collectionData.leftBarData.data.currentCat, cats: cats, custPart: custPart };
}

function makeQuery(start, filters) {
  if (location.pathname.indexOf('my-favorites') > -1) {
    if(start == undefined) return;
  }
  start = start?.indexOf('?') != -1 ? start?.split('?')[0] + '?' : start;
  let query = start;
  let preeSelectedSizes = '';
  let vendorSelect = 0;
  let preferences = null;

  let filterData = filters;

  // if (this.props.customer.customer != "not_login" && this.props.customerPreferences.customerPreferences.preferences != null && this.props.customerPreferences.customerPreferences.preferences.sizes != undefined) {
  /* if (this.props.customerPreferences.customerPreferences.preferences != null && this.props.customerPreferences.customerPreferences.preferences.sizes != undefined) {
        preferences = this.props.customerPreferences.customerPreferences.preferences
        preeSelectedSizes = preferences.sizes;
    }*/
  let leftBarSpecificFilter = sessionStorage.getItem('leftBarSpecificFilter');
  let leftBarSpecificPath = sessionStorage.getItem('leftBarSpecificPath');
  let pathName = (typeof window != undefined && window != undefined) ? window.location.pathname : ""   
  if(leftBarSpecificFilter != undefined && leftBarSpecificFilter != 'undefined' && leftBarSpecificPath != undefined && leftBarSpecificPath != 'undefined' && leftBarSpecificPath == pathName) {
    query += `filter_tags_any_list=${leftBarSpecificFilter},`;
  }
  else {
    sessionStorage.removeItem('leftBarSpecificFilter');
    sessionStorage.removeItem('leftBarSpecificPath')
    query += 'filter_tags_any_list=';
  }  
  if (filterData.filters != undefined && filterData.filters.length != 0) {
    filterData.filters = filterData.filters.replace(',', '|');
    query += filterData.filters + '|';
  }
  filterData.colors != undefined &&
    filterData.colors.filter((color) => {
      if ((color.selected == true || color.selected == undefined) && color.color != undefined && !color.paterns) query += color.color + ',';
    });
  filterData.colors != undefined &&
    filterData.colors.filter((color, index) => {
      if ((color.selected == true || color.selected == undefined) && color.color != undefined && color.paterns) {
        if (query.indexOf('|') == -1) query += '|';
        query += color.color + ',';
      }
    });
  let pipeAdded = false;
  filterData.occasion != undefined &&
    filterData.occasion.filter((occ) => {
      if (occ.selected == true && occ.tag_name != undefined) {
        if (!pipeAdded) {
          query += '|';
          pipeAdded = true;
        }
        query += occ.tag_name + ',';
      }
    });
  pipeAdded = false;

  // if(location.pathname.indexOf('/my-favorites') > -1){
    filterData.otherFilter != undefined &&
    filterData.otherFilter.filter((occ) => {
      if (occ.selected == true && occ.tag_name != undefined) {
        if (!pipeAdded) {
          query += '|';
          pipeAdded = true;
        }
        query += occ.tag_name + ',';
      }
    });
    pipeAdded = false;
  // }

  if (filterData.filter != undefined && filterData.filter.length > 0) {
    filterData.filter.map((filter) => {
      // console.log('filter - subfilter_categories', filter);
      filter.subfilter_categories.filter((sfcat) => {
        pipeAdded = false;
        sfcat.subfilter_lists.filter((list) => {
          if (list.selected == true) {
            if (!pipeAdded) {
              query += '|';
              pipeAdded = true;
            }
            list.tags.filter((tag) => {
              query += tag.tag + ',';
            });
          }
        });
      });
    });
  }
  pipeAdded = false;
  filterData.season != undefined &&
    filterData.season.filter((season) => {
      if (season.selected == true && season.name != undefined) {
        if (!pipeAdded) {
          query += '|';
          pipeAdded = true;
        }
        query += season.name + ',';
      }
    });
  query += '&filter_vendor_ids=';

  filterData.brands != undefined &&
    filterData.brands.filter((brand) => {
      if (brand.selected == true) {
        vendorSelect = 1;
        query += brand.brand_id + ',';
      }
    });
  if (vendorSelect == 1) query = query.slice(0, -1);
  query += '&filter_sizes=';
  filterData.sizes != undefined &&
    filterData.sizes.filter((size) => {
      if (size.selected == true && size.size != ''){ 
        query += size.size + ',';
        if(query.indexOf('NONE,') ==-1)
        query += 'NONE,';
        if(query?.toLowerCase()?.indexOf('petite')>-1){
          query += 'p,';
        }
      }
    });
  if(filterData.price && filterData.price.length > 0){
    query += '&price_Filter='+filterData.price.join()  
  }  

  query += '&';
  return query;
}
function populateSearchFilters(searchFilter, isPreferences) {
  return async (dispatch, getState) => {
    var collectionPage = getState().collectionPage;
    let filterData = collectionPage.filterData;

    let pf = searchFilter;
    pf = pf.split('&');
    let preeSetFiltersInternal = [];
    pf.filter((value, index) => {
      value = value.split('=');
      preeSetFiltersInternal[value[0]] = value[1];
    });
    ///clear all////
    // localStorage.removeItem('isProductPage')
    let sizes = [];
    if (isPreferences == undefined || Object.keys(isPreferences).length == 0) {
      if (filterData.data.sizes != undefined) {
        sizes = filterData.data.sizes.map((size) => {
          return { size: size.size, selected: (size.selected = false), index: size.index };
        });
      }
    } else if (isPreferences.sizes == '') {
      if (filterData.data.sizes != undefined) {
        sizes = filterData.data.sizes.map((size) => {
          return { size: size.size, selected: (size.selected = false), index: size.index };
        });
      }
    }
    let colors = [];
    if (filterData.data.colors != undefined) {
      colors = filterData.data.colors.map((color) => {
        if (color.paterns) return { color: color.color, selected: false, paterns: true };
        else return { color: color.color, selected: false, paterns: false };
      });
    }
    let brands = [];
    if (filterData.data.brands != undefined) {
      brands = filterData.data.brands.map((brand) => {
        return { brand: brand.brand, brand_id: brand.brand_id, selected: false };
      });
    }
    let occasion = [];
    if (filterData.data.occasion != undefined) {
      occasion = filterData.data.occasion.map((acc) => {
        acc.selected = false;
      });
    }
    let price = []
    if (filterData.data.price != undefined) {
      price = [];
    }
    if(searchFilter?.indexOf('min') > -1 && searchFilter?.indexOf('max') > -1){
      let priceLImit = searchFilter.split(',')
      let min = priceLImit[0].split('=')
      let max = priceLImit[1].split('=')
      price.push(min[1])
      price.push(max[1])
      window.history.pushState('', '', "/"+window.location.href.substring(window.location.href.lastIndexOf('/') + 1).split("?")[0]);
    }
    let filter = [];
    if (filterData.data.filter != undefined) {
      if (filterData.data.filter.length > 0) {
        filterData.data.filter.map((filter) => {
          filter.subfilter_categories.map((sunFilter) => {
            sunFilter.subfilter_lists.map((subList) => {
              subList.selected = false;
            });
          });
        });
      }
    }
    filter = filterData.data.filter;
    if (sizes.length > 0) filterData.data.sizes = sizes;
    filterData.data.colors = colors;
    filterData.data.brands = brands;
    filterData.data.price = price;
    filterData.data.filter = filter;
    ////clear all////
    filterData = filterData.data;
    if ('color' in preeSetFiltersInternal) {
      preeSetFiltersInternal['color'] = preeSetFiltersInternal['color'].split(',');
      if (filterData.colors == undefined) {
        filterData.colors = [];
      }
      let defultPaterns = Gconfig.paterns_prints
      preeSetFiltersInternal['color'].filter((value, index) => {
        let paterns = false
        if(defultPaterns.indexOf(value) > -1) paterns = true
        filterData.colors.push({ color: value, selected: true, paterns: paterns });
      });
      delete preeSetFiltersInternal['color'];
    }

    if ('occasion' in preeSetFiltersInternal) {
      preeSetFiltersInternal['occasion'] = preeSetFiltersInternal['occasion'].split(',');
      if (filterData.occasion == undefined) {
        filterData.occasion = [];
      }
      if (preeSetFiltersInternal['occasion'] != '' && preeSetFiltersInternal['occasion'] != undefined) {
        preeSetFiltersInternal['occasion'].filter((value, index) => {
          filterData.occasion.push({ tag_name: value, selected: true });
        });
      }
      delete preeSetFiltersInternal['occasion'];
    }

    if ('pattern' in preeSetFiltersInternal) {
      preeSetFiltersInternal['pattern'] = preeSetFiltersInternal['pattern'].split(',');
      if (filterData.colors == undefined) {
        filterData.colors = [];
      }
      if (preeSetFiltersInternal['pattern'] != '' && preeSetFiltersInternal['pattern'] != undefined) {
        preeSetFiltersInternal['pattern'].filter((value, index) => {
          filterData.colors.push({ color: value, selected: true, paterns: true });
        });
      }
      delete preeSetFiltersInternal['pattern'];
    }
    if ('filters' in preeSetFiltersInternal) {
      filterData.filters = preeSetFiltersInternal['filters'];
    } else {
      filterData.filters = '';
    }

    Object.keys(preeSetFiltersInternal).map((filterType, index) => {
      let filterValue = preeSetFiltersInternal[filterType];
      if (filterValue == undefined) return;

      let filterValueSplited = filterValue.split(',');
      let newFilter = {};
      filterValueSplited.map((filterValue) => {
        if (filterValue.includes('_')) {
          let filterValueArr = filterValue.split('_');
          if (filterValueArr.length > 0) {
            let tag = filterValueArr[0];
            let tagId = parseInt(filterValueArr[1]);

            let filterName = filterType.replace('_', ' ');
            filterName
              .split(' ')
              .map((w) => w[0].toUpperCase() + w.substr(1).toLowerCase())
              .join(' ');

            let tagWebtext = tag.replace('-', ' ');
            if (newFilter.name == undefined) {
              newFilter = { name: filterName, subfilter_categories: [{ id: tagId, website_text: null, subfilter_lists: [{ website_text: tagWebtext, selected: true, tags: [{ tag: tag }] }] }] };
            } else {
              newFilter.subfilter_categories[0].subfilter_lists.push({ website_text: tagWebtext, selected: true, tags: [{ tag: tag }] });
            }
          }
        }
      });

      if (newFilter.name != undefined) {
        if (filterData.filter == undefined) {
          filterData.filter = [];
        }

        // console.log('newFilter === ', newFilter);
        filterData.filter.push(newFilter);
      }
    });

    if (filterData.sizes == undefined) {
      filterData.sizes = [];
    }
    // console.log('filterData====>>>', filterData);
    dispatch(fetchFilterDataSuccess(filterData));
  };
}
function populateFiltersWithPreferences(_preferences) {
  return async (dispatch, getState) => {
    var collectionPage = getState().collectionPage;
    if (isEmpty(collectionPage.filterData.data)) {
      var _filter = {};
      Object.entries(_preferences).map((x) => {
        if (x[0] == 'sizes' && x[1] != '') {
          _filter[x[0]] = x[1].split(',');
          _filter[x[0]] = _filter[x[0]].map((row) => {
            return { size: row, selected: true };
          });
        }
      });
      collectionPage.filterData.data = _filter;
      dispatch(fetchFilterDataSuccess(collectionPage.filterData.data));
    } else {
      // console.log(collectionPage.filterData);
    }
  };
}
async function collectionIndexFunction(preProps, _this) {
  let xexicuteFilter = false;
  let localCatfill = undefined;
  let localXexicute = '';
  if (window != undefined) {
    //debugger;
    // console.log("in update:" + window.location)
    if(window.location.href.indexOf('all?') > -1 && _this.props.catagoryFilter.indexOf('all') ==-1){
      localCatfill = 'all'
    }else{
       localCatfill = _this.props.catagoryFilter;
    }
    if (window.location.href.indexOf('?') != -1) localXexicute = window.location.href.split('?')[1];
    //this.props._filterQuerySubmit("null",null,null,null);
  }
  let oldQ = _this.props.collectionPage.paginationData.data.query;
  if(!oldQ && !_this.state.firstLoad){
    oldQ = "clothing?"
  }
  let _checkLocalCat = oldQ == undefined || oldQ.indexOf(localCatfill + '?') == -1;
  if (!_checkLocalCat) {
    _checkLocalCat = oldQ != undefined && oldQ.indexOf(localCatfill) > 0;
  }
  if (preProps.customer.customer == 'not_login' && _this.props.customer.customer != 'not_login') {
    await _this.props._getCustomerPreferences(false);
    await _this.setState({ firstLoad: true });
  }
  if (localXexicute != _this.state.xExicuteState) {
    await _this.setState({ firstLoad: true, xExicuteState: localXexicute });
  }
  let urlHit = (((oldQ != undefined || _this.state.firstLoad) && localCatfill != undefined && _checkLocalCat) || _this.state.firstLoad) && !_this.state.urlHitProcessing;
  if ((_this.props.collectionPage.paginationData.data.filterHit || urlHit || (sessionStorage.getItem('leftBarSpecificBtnClicked') != 'undefined' && sessionStorage.getItem('leftBarSpecificBtnClicked') != undefined)) && !_this.state.urlHitProcessing && !_this.props.systemSettings.loading) {
    xexicuteFilter = _this.state.firstLoad;
    await _this.setState({ urlHitProcessing: true, firstLoad: false });

    let toggleAvailble = await checkSizeFilterAvailble(_this.props);
    let query = oldQ;
    // if (this.props.customer.customer != "not_login") {
    //await this.props._getCustomerPreferences();

    if (!isEmpty(_this.props.customerPreferences.customerPreferences.preferences)) {
      await _this.props._populateFiltersWithPreferences(_this.props.customerPreferences.customerPreferences.preferences);
    }

    if ((_this.state.xExicuteState.indexOf('search') != -1 || (_this.state.xExicuteState.indexOf('min') != -1 && _this.state.xExicuteState.indexOf('max') != -1)) && (xexicuteFilter || _this.props.collectionPage.paginationData.data.noFoundClear == true)) {
      await _this.props._populateSearchFilters(_this.state.xExicuteState, _this.props.customerPreferences.customerPreferences.preferences);
    }

    // }
    let pageNo = 1;
    let paginationCookie = jsCookie.get('collectionRefresh')
    if(paginationCookie != undefined)
      paginationCookie = JSON.parse(paginationCookie)
    let currentPathName =  window.location.pathname;
    if (localStorage.getItem('isProductPage') && _this.props.collectionPage.paginationData.data.current_page) {
      pageNo = _this.props.collectionPage.paginationData.data.current_page;
      // localStorage.removeItem('isProductPage');
    }else if( _this.props.isServer && paginationCookie != undefined &&  currentPathName == paginationCookie.path){
      pageNo = paginationCookie.pageNo;
    }else if(_this.props.isServer == false && paginationCookie != undefined){
      jsCookie.remove('collectionRefresh')
    }else {
      pageNo = 1;
    }
    if (urlHit || (sessionStorage.getItem('leftBarSpecificBtnClicked') != 'undefined' && sessionStorage.getItem('leftBarSpecificBtnClicked') != undefined)) {
      if(localCatfill != undefined && localCatfill != "" && sessionStorage.getItem('leftBarSpecificBtnClicked') != 'undefined' && sessionStorage.getItem('leftBarSpecificBtnClicked') != undefined) {
        localCatfill = ''+localCatfill.split(',')[0]
      }
      query = makeQuery(localCatfill + '?', _this.props.collectionPage.filterData.data);
      sessionStorage.removeItem('leftBarSpecificBtnClicked')
      await _this.props._GetProductListbyhandle(query, pageNo, 2, urlHit, true, false, _this.state.sizeTogleValue, toggleAvailble).then(async (data) => {
        if (data.success) {
          await _this.setState({
            currentItems: data,
            loadingCurrentItems: false,
          });
          if(data.product_list && data.product_list.result_items.length == 0) {
            sessionStorage.removeItem('leftBarSpecificBtnClicked')
            sessionStorage.removeItem('leftBarSpecificFilter')
            sessionStorage.removeItem('leftBarSpecificPath')
          }
        } else {
          //Router.push('/')
        }
        await _this.setState({ urlHitProcessing: false });
      });

      return;
    }
    //console.log('filter is hit by user');

    query = makeQuery(query, _this.props.collectionPage.filterData.data);
    if (_this.props.collectionPage.paginationData.data.filterHit) {
      // console.log("----------------------......----------------------------......----------------------------......----------------------------......----------------------------......----------------------------......----------");
    }
    var onlyProductCall = 1;
    if (_this.props.customerPreferences.customerPreferences.preferences && preProps.customerPreferences.customerPreferences.preferences && _this.props.customerPreferences.customerPreferences.preferences.sizeTogleValue != preProps.customerPreferences.customerPreferences.preferences.sizeTogleValue) {
      onlyProductCall = 0;
    }
    if (_this.props.collectionPage.paginationData.data.clearCall == true) onlyProductCall = 2;
    _this.props._GetProductListbyhandle(query, _this.props.collectionPage.paginationData.data.current_page, onlyProductCall, false, true, false, _this.state.sizeTogleValue, toggleAvailble).then(async (data) => {
      if (data.success) {
        await _this.setState({
          currentItems: data,
          loadingCurrentItems: false,
        });
      } else {
        Router.push('/');
      }
      await _this.setState({ urlHitProcessing: false });
    });
  }
}

async function getHomePageSliderData (type, isLogin, page,from,isAutoHide) {
  let query=''
  if(page == undefined || page == null) {
    page = 1
  }
  let customer_ip = jsCookie.get('customer_ip');
  let request_guid = jsCookie.get('request_guid');
  let customer_token = getAuthTokenfromcookie(false);
  if(from!=''&& from=="holidayFav")
  {
   query=type
  }else {
    if(type == "what-you-missed"){
      query =  DerivedAPI.COLLECTION_API_HANDLE_URL+type+`?filter_tags_any_list=&filter_vendor_ids=&filter_sizes=&items_per_page=48&excludes=stats,categorylist&sort_by=days&sort_order=asc&page=${page}&show_hidden_items=2&in_stock=0&in_stock_sizes=0&exclude_tags=bump-photo&auto_hide=${isAutoHide}`
    }
    else {
      query =  DerivedAPI.COLLECTION_API_HANDLE_URL+type+`?filter_tags_any_list=&filter_vendor_ids=&filter_sizes=&items_per_page=48&excludes=stats,categorylist&sort_by=pos&sort_order=desc&page=${page}&show_hidden_items=2&in_stock=0&in_stock_sizes=0&exclude_tags=bump-photo&auto_hide=${isAutoHide}`
    }
  }
  
  return fetch(query, {
    method: 'GET',
    headers: isLogin == true ? {'X-Request-For': customer_ip, 'guid': request_guid, 'Authorization': 'Bearer ' + customer_token } : {'X-Request-For': customer_ip, 'guid': request_guid }
  }).then(response => response.json()).then(data => {
      return data
  })
}

async function getBackInStockSliderData (type, isLogin, size,tags, isAutoHide) {
  
  let customer_ip = jsCookie.get('customer_ip');
  let request_guid = jsCookie.get('request_guid');
  let customer_token = getAuthTokenfromcookie(false);
  let query =  DerivedAPI.COLLECTION_API_HANDLE_URL+type+`?filter_tags_any_list=${tags}&filter_vendor_ids=&filter_sizes=${size}&items_per_page=48&excludes=stats,categorylist&sort_by=days&sort_order=asc&page=1&show_hidden_items=2&in_stock=1&in_stock_sizes=1&other_filter=only_backinstock&isprice_for_customer=true&exclude_tags=bump-photo&auto_hide=${isAutoHide}`
  return fetch(query, {
    method: 'GET',
    headers: isLogin == true ? {'X-Request-For': customer_ip, 'guid': request_guid, 'Authorization': 'Bearer ' + customer_token } : {'X-Request-For': customer_ip, 'guid': request_guid }
  }).then(response => response.json()).then(data => {
      return data
  })
}

function clearPaginationDataQuery() {
  return async (dispatch, getState) => {
    let paginationData = getState().collectionPage.paginationData;
    paginationData.data.query = "all?"
    dispatch(fetchPaginationDataSuccess(paginationData.data));
  }
}

async function getCuratedCollectionData (collection_type,isLogin,isAutoHide) {
  
  let customer_ip = jsCookie.get('customer_ip');
  let request_guid = jsCookie.get('request_guid');
  let customer_token = getAuthTokenfromcookie(false);
  let query =  DerivedAPI.COLLECTION_API_HANDLE_URL+collection_type+`?filter_tags_any_list&filter_vendor_ids=&filter_sizes&items_per_page=50&excludes=stats,categorylist&sort_by=pos&sort_order=dsc&page=1&show_hidden_items=2&in_stock=1&in_stock_sizes=1&isprice_for_customer=true&exclude_tags=bump-photo&auto_hide=${isAutoHide}`
  return fetch(query, {
    method: 'GET',
    headers: isLogin == true ? {'X-Request-For': customer_ip, 'guid': request_guid, 'Authorization': 'Bearer ' + customer_token } : {'X-Request-For': customer_ip, 'guid': request_guid }
  }).then(response => response.json()).then(data => {
      return data
  })
}

function clearBreadCrumbs() {
  return async (dispatch, getState) => {
    let prevState = getState().collectionPage?.leftBarData.data
    if((prevState && Object.keys(prevState).length > 0) && prevState?.currentCat?.cat?.name != undefined && (prevState?.currentCat?.cat?.name !== 'Clothing' && prevState?.currentCat?.cat?.name !== 'Accessories')) {
      dispatch(fetchLeftBarDataSuccess({}))
    }
  }
}

async function getCommingSoonSliderData (type, isLogin,tag, page,from,isAutoHide) {
  let query=''
  if(page == undefined || page == null) {
    page = 1
  }
  let customer_ip = jsCookie.get('customer_ip');
  let request_guid = jsCookie.get('request_guid');
  let customer_token = getAuthTokenfromcookie(false);
  if(from!=''&& from=="holidayFav")
  {
   query=type
  }else{
    query =  DerivedAPI.COLLECTION_API_HANDLE_URL+type+`?filter_tags_any_list=${tag}&filter_vendor_ids=&filter_sizes=&items_per_page=48&excludes=stats,categorylist&sort_by=pos&sort_order=desc&page=${page}&show_hidden_items=1&in_stock=0&in_stock_sizes=0&exclude_tags=bump-photo&auto_hide=${isAutoHide}`
  }
  
  return fetch(query, {
    method: 'GET',
    headers: isLogin == true ? {'X-Request-For': customer_ip, 'guid': request_guid, 'Authorization': 'Bearer ' + customer_token } : {'X-Request-For': customer_ip, 'guid': request_guid }
  }).then(response => response.json()).then(data => {
      return data
  })
}

export { GetProductList, getMainCategory, GetProductListbyhandle, onlyUnique, productHeading, makeQuery, generateSoldOutMessage, populateFiltersWithPreferences, populateSearchFilters, collectionIndexFunction,getHomePageSliderData, getBackInStockSliderData, clearPaginationDataQuery,getCuratedCollectionData,
  clearBreadCrumbs, getCommingSoonSliderData };
